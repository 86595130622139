exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-news-js": () => import("./../../../src/pages/lab-news.js" /* webpackChunkName: "component---src-pages-lab-news-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-people-alumni-js": () => import("./../../../src/pages/people/alumni.js" /* webpackChunkName: "component---src-pages-people-alumni-js" */),
  "component---src-pages-people-current-members-js": () => import("./../../../src/pages/people/current-members.js" /* webpackChunkName: "component---src-pages-people-current-members-js" */),
  "component---src-pages-people-index-js": () => import("./../../../src/pages/people/index.js" /* webpackChunkName: "component---src-pages-people-index-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-cellular-copper-js": () => import("./../../../src/pages/research/cellular-copper.js" /* webpackChunkName: "component---src-pages-research-cellular-copper-js" */),
  "component---src-pages-research-cox-assembly-js": () => import("./../../../src/pages/research/cox-assembly.js" /* webpackChunkName: "component---src-pages-research-cox-assembly-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-mitochondrial-dysfunction-immune-suppression-js": () => import("./../../../src/pages/research/mitochondrial-dysfunction-immune-suppression.js" /* webpackChunkName: "component---src-pages-research-mitochondrial-dysfunction-immune-suppression-js" */)
}

